@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');

*{
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
}

/* html{
  scroll-behavior: smooth;
} */

