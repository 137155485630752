.App {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* overflow: hidden; */
  /* pointer-events: none; */
  /* z-index: 1; */
}

/* .scroll {
  padding: 2vh 0;
  pointer-events: none;
} */

/* html,
body {
  height: 100%;
  overscroll-behavior-y: none;
} */

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color:#090909;
  /* font-size: 48px; */
}


::-webkit-scrollbar {
  width: 5px;
}
/* ::-webkit-scrollbar {
  display: none;
} */

::-webkit-scrollbar-track {
  background-color: #fff;
  box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(85, 94, 86);
  border-radius: 5px;
  box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.2);
}
